<template>
  <div class="align">
    <loader v-if="loadingDeals"></loader>
    <div class="contentBlock"  v-if="!loadingDeals  &&
    activeDealData('DealInfo').StateDeal == 0">
      <div class="title">
        <h3>Фактические платежи</h3>
      </div>
      <div class="body">
        <ul id="" v-if="PayList.length > 0" id="payList">
          <li v-for="(item,index) in PayList" :key="index">
            <p>{{item.pDate | timeFromXtoDateView}}</p>
            <b :inner-html.prop="item.pSum | moneyFormat"></b>
          </li>
        </ul>
        <div v-else>
          <p>Нет информации о платежах</p>
        </div>
      </div>
    </div>
    <div v-if="!loadingDeals  &&
    activeDealData('DealInfo').StateDeal !== 0">
      <div class="layoutBorder">
        <div class="layoutHeader">
          <h4>{{demandStatusToTxt(activeDealData('DealInfo').StateDeal)}}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters,mapState} from 'vuex';
  export default {
    name: "PayList",
    components: {
      loader: () => import('@/components/other/loader'),
    },
    methods:{
      demandStatusToTxt(status){
        let out = '';
        switch (status) {
          case 0:
            out = 'Договор активный';
            break;
          case 1:
            out = 'Договор успешно закрыт';
            break;
          case 2:
            out = 'Договор на стадии оформления';
            break;
          default:
            out = 'Нет статуса';
        }
        return out;
      }
    },
    computed:{
      ...mapState({
        loadingDeals:state=>state.contentData.deals.isLoading
      }),
      ...mapGetters([
        'activeDealData',
      ]),
      PayList(){
        return this.activeDealData('PayList')
      }
    },
    mounted(){
      this.$parent.selectedSection = 0
      this.$myApi.checkVersion();
      window.scrollTo(0,0);
    }
  }
</script>

<style scoped>
  ul{display: flex;flex-direction: column-reverse;justify-content: flex-start;}
  li{display: flex;align-items: center;justify-content: space-between;margin: 2vw 0;border-bottom: 2px solid #DFE0E1}
  p{margin: 0;}

  @media screen and (min-width: 760px){
    li{margin: 1vw 0;}
  }
  @media screen and (min-width: 1200px){
    li{margin: 7px 0;justify-content: flex-start;}
    li p{margin-right: 10px;}

  }
</style>
